import React from "react";
import { BsStars } from "react-icons/bs";
import titleBG from "@/public/images/assets/titleBG.svg";

type Props = {
  title: string;
  subTitle: string;
  image?: boolean;
};

const Index: React.FC<Props> = ({ title, subTitle, image = true }) => {
  return (
    <div
      className="w-full mt-10 py-4 lg:bg-cover overflow-clip"
      style={{
        backgroundImage: image ? `url(${titleBG.src})` : undefined,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container flex flex-col items-center justify-center gap-4 bg-contain">
        <div
          className="flex items-center gap-2 py-2 px-6 rounded-3xl"
          style={{
            boxShadow: "0px 0px 0px 3px rgba(143, 105, 248, 0.10)",
            backgroundImage:
              "linear-gradient(0deg, rgba(99, 46, 249, 0.13) 0%, rgba(99, 46, 249, 0.13) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%)",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <BsStars />
          <span>{subTitle}</span>
        </div>
        <div
          className="text-center text-white text-xl md:text-3xl font-semibold capitalize max-w-2xl mb-10"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default Index;
